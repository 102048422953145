import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout"
import Next from "../../components/next"
import Tooltip from "../../components/tooltip"

const Page = ({data}) => (
  <Layout title="Smartix" keywords={[`rail api`, `train ticket`, `api`]} submenu={data.allRailapisubmenuJson.edges}>
    <content>
      <h1>Data Management</h1>

      <p>Rail data comes from many sources: <Tooltip>RCS</Tooltip>, <Tooltip>RCS-Flows</Tooltip>, <Tooltip>Fares</Tooltip>, <Tooltip>NRE</Tooltip>, <Tooltip>IDMS</Tooltip>. 
      Unfortunately the standard of data provided for use by retailers is extremely poor. There are contradictions, missing data and simply incorrect data spread across 
      all these feeds.</p>

      <p>As a licensed retailer, you will have access to all of these feeds and be expected to use them to display the correct information in your retail application. Additionally, 
      we will provide you with our <strong>corrected data feeds</strong>. Each day we receive the latest data, apply our automated corrections and filter out unusable data in order to 
      produce a set of valid data can can be used with confidence.</p>

      <p>We have an automated messaging system (<Tooltip>AWS</Tooltip> <Tooltip>SNS</Tooltip>) that will inform you of data updates within seconds of that update being available.</p>


      <Next submenu={data.allRailapisubmenuJson.edges}/>

    </content>
  </Layout>
)

export const query = graphql`
  query {
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }
  } 
`

export default Page